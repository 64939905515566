@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://code.highcharts.com/7.0.0/css/themes/dark-unica.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --color-1 : #480E3E;
  --color-2 : #40136A;
  --color-3 : #EFC912;
  --color-black : #000000;
  --color-white : #ffffff;
}

*                                       { margin: 0;padding: 0;box-sizing: border-box; }
h1,h2,h3,h4,h5,h6                       { font-family: 'Ubuntu', sans-serif; }
body,span,p                             { font-family: 'Spartan', sans-serif; }
.side-nav::-webkit-scrollbar            { width: 0px; }
nav.side-nav                            { width: 16rem; }
ul.deals-carousel li                    { margin-bottom: 12px; }
ul.deals-carousel li:last-child         { margin-bottom: 0; }
ul.deals-carousel .deal-box .icon-box   { padding: 5px 8px;border-radius: 8px;color: var(--color-white);margin-right: 6px;font-weight: 800; }
ul.deals-carousel .deal-box .dl-title   { font-size: 12px;font-weight: 600;margin-bottom: 4px;@apply text-black; @apply dark:text-white; }
ul.deals-carousel .deal-box .dl-time    { font-size: 10px;font-weight: 600;opacity: 0.4;@apply text-black; @apply dark:text-white; }
ul.deals-carousel .deal-box .deal-price { font-size: 10px;text-align: right;font-weight: 600; }
ul.deals-carousel .deal-box .deal-price .price{ color: var(--color-3);font-weight: 600;margin-top: 2px; }
/* .hero-section                           { background: linear-gradient(90deg, rgb(0 0 0) 0%, rgb(0 36 84) 100%);position: relative; } */

.tokan-input input                      { padding: 0;border: 0;outline: none;text-align: right;font-weight: 500;background-color: transparent; }
.DocSearch-Input:focus,
.tokan-input input:focus                { outline: none;border: 0;box-shadow: none; }
.separator a                            { width: 24px;height: 24px;line-height: 24px;border-radius: 50%;background: #fff;box-shadow: 0 3px 10px rgb(0 0 0 / 20%);display: flex;align-items: center;justify-content: center;transition: all 0.5s; }
.separator a:hover                      { transform: translateY(10px) rotate(180deg); }
.DocSearch-Form                         { display: flex;min-width: 0;flex: 1 1 auto;align-items: center; }
.DocSearch-Input                        { -webkit-appearance: none;appearance: none;background: transparent;height: auto;font-size: 1rem;color: #0f172a;margin-left: 0.75rem;margin-right: 1rem;flex: auto;min-width: 0;padding: 0;border: 0; }
.favourite-token .favorite-close        { font-size: 12px;background: #fff;box-shadow: 0 3px 6px rgb(0 0 0 / 10%);width: 18px;height: 18px;padding: 4px;display: flex;align-items: center;justify-content: center;border-radius: 50%;position: absolute;top: -8px;right: -8px;visibility: hidden;transition: all 0.5s; }
.favourite-token:hover .favorite-close  { visibility: visible; }
.token-list                             { max-height: 36vh;overflow-y: auto; }
.token-list::-webkit-scrollbar          { width: 4px; }
.token-list::-webkit-scrollbar-track    { background: transparent; }
.token-list::-webkit-scrollbar-thumb    { background: rgb(156, 156, 156); }
.token-list::-webkit-scrollbar-thumb:hover{ background: #555; }
.select-token                           { backdrop-filter: blur(6px);-webkit-backdrop-filter: blur(6px);-o-backdrop-filter: blur(6px);-ms-backdrop-filter: blur(6px);-moz-backdrop-filter: blur(6px); }
/* .tab-content                            { display: none; }
.tab-content.active                     { display: block; } */
.tabs .tab.active                       { @apply border-b-2; @apply text-indigo-500; @apply border-indigo-500; }
.dark .tabs .tab.active                 { @apply border-b-2; @apply text-green-400; @apply border-green-500; }
ul#ulMenu li a.active                   { @apply text-black; @apply bg-black; @apply bg-opacity-5;@apply dark:text-white; @apply pl-4; @apply dark:bg-white; @apply dark:bg-opacity-25; @apply font-semibold; }
/* width */
::-webkit-scrollbar                     { width: 10px;height: 10px; }
/* Track */
::-webkit-scrollbar-track               { @apply bg-black }
/* Handle */
::-webkit-scrollbar-thumb               { @apply bg-white; @apply bg-opacity-90;border-radius: 100vh; @apply border-2; @apply border-black }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover         { background: #a0aec0; }
/* Toggle B */
input:checked ~ .dot                    { transform: translateX(100%); @apply bg-green-500 }
input:checked ~ .block                  { @apply bg-green-600; @apply bg-opacity-25; }
.DocSearch-Search-Icon path             { stroke: #000000; }
.dark .DocSearch-Search-Icon path       { stroke: #ffffff; }
.overview-chart .highcharts-background  { fill: transparent; }
.overview-chart .highcharts-pie-series .highcharts-point{ stroke: transparent; }
.overview-chart .highcharts-legend-item.highcharts-pie-series{ display: none; }
.hero-chart .highcharts-background      { fill: transparent; }
#theme-toggle-light-icon                { @apply hidden; }
.dark #theme-toggle-dark-icon           { @apply hidden; }
.dark #theme-toggle-light-icon          { @apply block; }
.fency-checkbox input:checked ~ .block  { @apply bg-pink-500; @apply bg-opacity-100; }

@media(max-width:1024px){
  .navbar-menu .side-nav              { transform: translateX(-16rem);transition: all 0.5s;-webkit-transition: all 0.5s;-o-transition: all 0.5s;-moz-transition: all 0.5s;-ms-transition: all 0.5s; }
  .navbar-menu .side-nav.active       { transform: translateX(0); }
}

@media(max-width:767px){
  .token-list                         { max-height: 50vh; }
}